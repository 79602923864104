.MWChip {
  @apply text-xs;
  @apply px-3 py-2;
  @apply leading-tight;
  @apply font-medium;
  @apply rounded-md;
  @apply line-clamp-1;
  @apply flex flex-row items-center justify-center;
  @apply transition-colors;
  @apply ease-in-out;
  @apply duration-200;
}

.MWChipActive {
  @apply bg-green-600;
  @apply text-green-100;
}

.MWChip:focus {
  @apply outline-none;
  @apply ring-2;
}

.MWChip label {
  @apply flex-grow;
  @apply flex-shrink;
}
