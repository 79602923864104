.MWButton {
  @apply duration-200;
  @apply transform;
  @apply transition-all;
  @apply space-x-1 leading-tight;
  @apply focus:outline-none focus:ring-2 focus:ring-green-500 ring-opacity-20;
  @apply font-medium;
  @apply text-center;
}

.MWButton:active {
  @apply scale-90;
}

.MWButton[color='default'] {
  @apply bg-green-600 text-white;
}

.MWButtonHilight {
  @apply opacity-60;
}

.MWButtonHilight:hover {
  @apply opacity-100;
}
