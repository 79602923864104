.CollectionItem {
  @apply py-2 px-1;
  @apply font-bold;
  @apply text-green-600;
  @apply transition-all;
  @apply duration-150;
  @apply transform;
  @apply flex-grow-0;
  @apply flex-shrink-0;
}

.CollectionItemActive {
  @apply px-3;
  @apply text-black;
  @apply border-b-4;
  @apply border-green-600;
}

.SpecialTab {
  @apply bg-green-600;
  @apply text-white;
  @apply rounded-t-md;
  @apply flex;
  @apply px-3;
  max-width: 240px;

}

.SpecialTabLabel {
  @apply line-clamp-1
}
