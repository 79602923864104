/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --ion-color-primary: #007a3e;
  --ion-color-primary-rgb: 0, 122, 62;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #015f31;
  --ion-color-primary-tint: #03aa58;

  --ion-color-contrast: #007a3e;
}

/* Increasing iOS Alert Input size
  - if a font is less than 16px on ios
  iOS will zoom the UI */
.alert-input.sc-ion-alert-ios {
  font-size: 16px !important;
}

.ion-page.ion-page-hidden {
  display: none;
}

/* Scroll Bar Customizations  */
body::-webkit-scrollbar {
  @apply w-2;
  @apply bg-transparent;
  @apply rounded-full;
  @apply border-none;
}

body::-webkit-scrollbar-track {
  @apply border-none;
  @apply bg-transparent;
  @apply p-1;
}

body::-webkit-scrollbar-thumb {
  @apply bg-gray-400;
  @apply rounded-full;
  @apply w-1;
}

.searchbar-search-icon {
  color: var(--ion-color-primary) !important;
  @apply pr-1;
}

body {
  /* --ion-color-primary: #007a3e; */
}

ion-tab-button.tab-selected {
  @apply text-white;
}


/* Fixes since we are not using Ionics Fixed Page Layout  */
ion-toast {
  @apply fixed;
}

ion-toast::part(container) {
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2) !important;
  border: solid 1px rgba(0, 0, 0, 0.1) !important;
  @apply rounded-lg;
}

ion-modal.product-detail-modal .modal-wrapper,
ion-modal.vendor-modal .modal-wrapper,
ion-modal.endorse-modal .modal-wrapper {
  min-height: 85vh;
  @apply relative;
}

/** Address lack of styling on ion-list-header on android **/
ion-list-header.md {
  @apply pl-4;
  @apply uppercase;
  @apply text-sm;
  @apply font-semibold;
  @apply text-gray-600;
}

ion-list-header.ios {
  @apply pb-2;
}

ion-list-header ion-button {
  @apply h-7;
}

.modal-wrapper {
  box-shadow: 0 12px 30px -5px rgba(0, 0, 0, 0.2) !important;
}

ion-button:focus-within::part(native),
ion-item.ion-activatable:focus-within::part(native) {
  @apply ring-2;
  @apply ring-blue-400;
  @apply ring-inset;
}

.mw-container {
  @apply max-w-screen-md;
  @apply mx-auto;
  @apply w-full;
}

.empty-block {
  @apply text-center;
  @apply p-6;
  @apply rounded-xl;
  @apply bg-white;
  @apply flex-grow-0;
  @apply flex-shrink-0;
  @apply max-w-xs;
  @apply mx-auto;
}

.empty-block h1,
.empty-block h2,
.empty-block h3,
.empty-block h4,
.empty-block h5 {
  @apply text-lg;
  @apply font-bold;
  @apply leading-tight;
  @apply my-6;
}

.mw-modal-sheet .modal-wrapper {
  @apply max-h-96;
  @apply w-72;
  @apply rounded-2xl;
}

.modal-wrapper[role="dialog"] {
  box-shadow: 0 10px 40px -2px rgba(0, 0, 0, 0.4) !important;
  border: solid 1px rgba(0, 0, 0, 0.2);
  @apply lg:max-h-full;
  @apply overflow-y-auto;
  /* @apply lg:max-h-screen; */
}

.plt-desktop .show-modal.tall .modal-wrapper[role='dialog'] {
  height: 90vh;
}

ion-tab-button ion-icon {
  @apply transition-all;
  @apply duration-500;
  @apply ease-in-out;
}

.flex-fill {
  @apply w-full;
  @apply flex-grow;
  @apply flex-shrink;
}

.flex-stiff,
.flex-fixed {
  @apply flex-grow-0;
  @apply flex-shrink-0;
}

ion-button.ios:active {
  @apply scale-95;
}

/* Ios Will zoom in on inputs less than 16px */
.ios input {
  font-size: 18px;
}

ion-modal {
  position: fixed !important;
}

ion-modal .ion-page {
  height: 100%;
}

ion-tab-button.tab-selected ion-icon {
  @apply transform scale-125;
}

/**
* Fixes an issue where the arrows are not inline with the total on the product buy bar
*/
ion-select::part(text),
ion-select::part(icon) {
  @apply inline-flex;
}

ion-select::part(icon) {
  @apply absolute right-5;
}

ion-searchbar .searchbar-cancel-button {
  display: none !important;
}

ion-searchbar.searchbar-has-focus .searchbar-cancel-button {
  display: block !important;
}

span.origin, span.processor {
  display: block;
  padding-top: 6px;
  font-size: 8pt;
  text-transform: uppercase;
}

.ingredients {
  font-size: 8pt;
}

span.origin::before {
  content: "SOURCED FROM: ";
  font-weight: bold;
}

span.processor::before {
  content: "PROCESSED BY: ";
  font-weight: bold;
}

.ingredients:before {
  content: "Ingredients: ";
  font-weight: bold;
}

.footer .item {
  @apply opacity-100;
}

.ProductBuyBar ion-select::part(text),
.VariantSelect::part(text) {
  @apply line-clamp-1;
  @apply w-9/12;
  @apply truncate;
  @apply whitespace-normal;
}

.ProductBuyBar ion-select::part(icon) {
  @apply top-1/2;
  transform: translateY(-50%);
}

.ProductBuyBarQty ion-select::part(text) {
  @apply w-full;
}

.ChatBubble main a {
  @apply text-green-600;
}

.ChatBubble main a:hover {
  @apply text-green-500;
}

.PostCard .PostCard__image img {
  width: 100%;
  height: 192px;
  object-fit: cover;
}

.no-scroll {
  overflow: hidden;
}

.InitLoader .InitLoader-ellipses::after {
  overflow: hidden;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026";
  width: 0;
}

@keyframes ellipsis {
  to {
    width: 20px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 20px;
  }
}

.Blog__body p {
  @apply mb-3;
}

.Blog__body p:last-of-type {
  @apply mb-0;
}

.Blog__body p br {
  @apply hidden;
}

.Blog__body p a {
  @apply text-green-600;
  @apply hover:text-green-500;
  @apply font-medium;
}

.Blog__body hr {
  @apply my-4;
}

.Blog__body ul {
  @apply list-disc;
  @apply pl-10;
  @apply mb-3;
}

.Blog__body ol {
  @apply list-decimal;
  @apply pl-10;
  @apply mb-3;
}

.Blog__body p img {
  @apply mx-auto;
}

/* Like Button Default/Like styles */
.like .isLiked .icon-inner .ionicon .ionicon-fill-none {
  fill: green;
}

/* Searchbar Adjustments that can't be made in tailwind/Ionic */
ion-toolbar ion-searchbar {
  padding-bottom: 0 !important;
  height: 100% !important;
}

.searchbar-input-container.sc-ion-searchbar-ios {
  padding-bottom: 0 !important;
}

.sc-ion-searchbar-md-h {
  box-shadow: none !important;
  --box-shadow: none;
}

ion-searchbar {
  --box-shadow: none;
}

.grecaptcha-badge {
  display: none !important;
}

@media (min-width: 768px) {
  .PostCard .PostCard__image img {
    height: 320px;
  }
}
