.ProductTileRibbonGreen {
  @apply bg-green-500 text-white;
  @apply absolute -right-12 top-4 z-10;
  @apply text-center w-40;
  @apply px-6 py-1;
  @apply rotate-45 transform transition-all;
  line-height: 24px;
}

.ProductTileRibbonBlue {
  @apply bg-blue-600 text-white;
  @apply absolute -right-12 top-4 z-10;
  @apply text-center w-40;
  @apply px-6 py-1;
  @apply rotate-45 transform transition-all;
  line-height: 24px;
}

.ProductTileRibbonOrange {
  @apply text-white bg-mw-orange;
  @apply absolute -right-12 top-4 z-10;
  @apply text-center w-40 text-sm;
  @apply px-6 py-1;
  @apply rotate-45 transform transition-all;
  line-height: 24px;
}

.ProductTileRibbonPurple {
  @apply text-white text-sm tracking-tight;
  @apply absolute -right-12 top-4 z-10;
  @apply text-center w-40;
  @apply px-6 py-1;
  @apply rotate-45 transform transition-all;
  line-height: 24px;
  background-color: #8C3481;
}

.ProductTileRibbonPink {
  @apply text-white text-sm tracking-tight bg-pink-600;
  @apply absolute -right-12 top-4 z-10;
  @apply text-center w-40;
  @apply px-6 py-1;
  @apply rotate-45 transform transition-all;
  line-height: 24px;
}
