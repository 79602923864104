.qty {
  @apply flex;
  @apply items-center;
  @apply rounded-md;
  @apply px-0 py-0;
  @apply transition-shadow duration-200;
}

.qty:focus,
.qty:active {
  @apply shadow-md;
}

.qtyValue {
  @apply w-5;
  @apply text-center;
}
